@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  @apply h-full w-full overflow-hidden p-0 m-0 bg-black font-SystemUI;
}

* {
  @apply outline-0 scroll-smooth;
}

.blur-bg {
  @apply rounded-full bg-blend-luminosity backdrop-blur-5xl bg-blurGray;
}

.window-transform {
  transform: translate(-50%, -40%);
}

.window-transform-f {
  z-index: 100 !important;
}

.window-transform-f:not(.safari) {
  transform: translate(-50%, -40%) !important;
}

.window-transform-f.safari {
  top: 0;
  transform: translate(0%, 0%) !important;
}

@layer base {
  @import "./font.css";
  @import "./variables.css";
}
