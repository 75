/** Regular */
@font-face {
  font-family: "SanFrancisco";
  font-weight: 400;
  src: url("../assets/fonts/SF-Pro-Text-Regular.woff2") format('woff2');
}

/** Medium */
@font-face {
  font-family: "SanFrancisco";
  font-weight: 500;
  src: url("../assets/fonts/SF-Pro-Text-Medium.woff2") format('woff2');
}

/** Semi Bold */
@font-face {
  font-family: "SanFrancisco";
  font-weight: 600;
  src: url("../assets/fonts/SF-Pro-Text-Semibold.woff2") format('woff2');
}

/** Bold */
@font-face {
  font-family: "SanFrancisco";
  font-weight: 700;
  src: url("../assets/fonts/SF-Pro-Text-Bold.woff2") format('woff2');
}

/** Bold */
@font-face {
  font-family: "SanFrancisco";
  font-weight: 800;
  src: url("../assets/fonts/SF-Pro-Text-Heavy.woff2") format('woff2');
}
